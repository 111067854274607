<template>
  <domain-items-list
    modal-id="tags-modal"
    modal-title="Tag"
    :api="{
      getItemsRequest, addItemRequest, updateItemRequest, resetItem, toggleStatus,
    }"
    :have-description="false"
    :item.sync="item"
  >
    <template #additional-inputs="{item}">
      <!-- Description -->
      <category-type-select
        :category.sync="item.category"
      />
    </template>
    <template #additional-filters="{searchQuery}">
      <b-col
        md="6"
      >
        <category-type-select
          :category.sync="searchQuery.category"
        />
      </b-col>
    </template>
  </domain-items-list>
</template>
<script>
import DomainItemsList from '@/common/components/GeneralSettings/domain-items/DomainItemsList.vue'
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import CategoryTypeSelect from '@/common/components/GeneralSettings/common/CategoryTypeSelect.vue'

export default {
  name: 'Tags',
  components: {
    DomainItemsList,
    CategoryTypeSelect,
  },
  provide() {
    return {
      resource: 'global-TagController',
    }
  },
  setup() {
    const {
      item, getItemsRequest, addItemRequest, updateItemRequest, resetItem, toggleStatus,
    } = domainItemApi('entities', '/internalops/tag')

    return {
      item, getItemsRequest, addItemRequest, updateItemRequest, resetItem, toggleStatus,
    }
  },
}
</script>
<style lang="">

</style>
