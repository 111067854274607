<template>
  <div>
    <custom-vue-select
      id="category-select"
      label="Select Category Type"
      placeholder="Choose Category Type"
      :value-reducer="option=>option.value"
      text-field="text"
      :options="[
        {text:'Business',value:'business'},
        {text:'Islamic Organization',value:'islamic organization'}
      ]"
      :selected.sync="categoryProxy"
    />
  </div>
</template>
<script>
import CustomVueSelect from '../../common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'CategoryTypeSelect',
  components: { CustomVueSelect },
  props: {
    category: { type: String, default: null },
    rules: { type: String, default: '' },
  },
  computed: {
    categoryProxy: {
      get() {
        return this.category
      },
      set(value) {
        this.$emit('update:category', value)
      },
    },
  },
}
</script>
<style lang="">

</style>
